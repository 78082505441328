// extracted by mini-css-extract-plugin
export var mainStyles = "crear-cuenta-module--mainStyles--3q6T7";
export var mainContentDiv = "crear-cuenta-module--mainContentDiv--jtuuL";
export var mainContentCenterDiv = "crear-cuenta-module--mainContentCenterDiv--3Q7Hk";
export var mainContentLeftTitleDiv = "crear-cuenta-module--mainContentLeftTitleDiv--11ckk";
export var mainContentLeftTLogoDiv = "crear-cuenta-module--mainContentLeftTLogoDiv--3_KNG";
export var logoImg = "crear-cuenta-module--logoImg--Ytdoc";
export var mainContentLeftRowDiv = "crear-cuenta-module--mainContentLeftRowDiv--2yCi6";
export var mainContentLeftFormDiv = "crear-cuenta-module--mainContentLeftFormDiv--aeq8w";
export var mainContentLeftFormLabelDiv = "crear-cuenta-module--mainContentLeftFormLabelDiv--2HTmx";
export var mainContentLeftFormInputDiv = "crear-cuenta-module--mainContentLeftFormInputDiv--3Hd6P";
export var mainContentLeftFormHalfDiv = "crear-cuenta-module--mainContentLeftFormHalfDiv--2bgSw";
export var mainContentLeftFormFullDiv = "crear-cuenta-module--mainContentLeftFormFullDiv--2miBQ";
export var mainContentLeftFormSelectDiv = "crear-cuenta-module--mainContentLeftFormSelectDiv--2BpRb";
export var createAccountDiv = "crear-cuenta-module--createAccountDiv--2hJkH";
export var createAccountButton = "crear-cuenta-module--createAccountButton--Z8C8D";
export var createAccountButtonDisabled = "crear-cuenta-module--createAccountButtonDisabled--1XoJR";
export var alreadyHaveAccountDiv = "crear-cuenta-module--alreadyHaveAccountDiv--egrmz";
export var alreadyHaveAccountLink = "crear-cuenta-module--alreadyHaveAccountLink--dnUFW";
export var mainContentLeftFormLabelLineDiv = "crear-cuenta-module--mainContentLeftFormLabelLineDiv--1oTPG";
export var mainContentLeftFormErrorDiv = "crear-cuenta-module--mainContentLeftFormErrorDiv--JnIpb";
export var errorMsgDiv = "crear-cuenta-module--errorMsgDiv--3uR4o";
export var mainContentLeftSubtitleDiv = "crear-cuenta-module--mainContentLeftSubtitleDiv--2gqtP";